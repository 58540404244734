import React, { useContext, useEffect, useState } from 'react'
import belissimo from '../../assets/imgs/belissimo.png'
import LeftArr from "../../assets/icons/roundedArrLeft.svg"
import RightArr from "../../assets/icons/roundedarrRight.svg"
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useGetPartnersMutation } from '../../RTXqueryApi/AllApi';
import { Contexts } from '../../context/Context';

export default function Foodproduct() {
  const { setLoader, langData } = useContext(Contexts)
  const [swiper, setSwiper] = useState(null);

  const handleSwiperUpdate = (swiperInstance) => {
    setSwiper(swiperInstance);
    swiperInstance.on('slideChange', () => {
      updateButtonOpacity(swiperInstance);
    });
    // Set initial button opacity
    updateButtonOpacity(swiperInstance);
  };

  const updateButtonOpacity = (swiperInstance) => {
    const isBeginning = swiperInstance.isBeginning;
    const isEnd =  swiperInstance.isEnd || false;
    const prevButton = document.querySelector('.swiper-prev');
    const nextButton = document.querySelector('.swiper-next');

    if (prevButton && nextButton) {
      if (isBeginning) {
        prevButton.style.opacity = '0.2';
      } else {
        prevButton.style.opacity = '1';
      }

      if (isEnd) {
        nextButton.style.opacity = '0.2';
      } else {
        nextButton.style.opacity = '1';
      }
    }
  };

  const [getPartners, { data: getPartnersData, isLoading: getPartnersLoading, isSuccess: getPartnersSuc }] = useGetPartnersMutation()
  const bannerData = {
    method: "get_our_partners"
  }

  useEffect(() => {
    getPartners(bannerData)
  }, [])

  useEffect(() => {
    setLoader(!getPartnersSuc)
  }, [getPartnersSuc])


  return (
    <div className='container mt-[30px] mx-auto'>
      <div className="my-[50px]">
        <div className="flex items-center justify-between mb-[30px]">
          <div className="grid">
            <h2 className='text-[28px]'>
              {langData.partners}
            </h2>
            <p className='text-[16px]'>{langData.partnersText}</p>
          </div>
          <div className="flex gap-[12px]">
            <img className="cursor-pointer swiper-prev" src={LeftArr} alt="" />
            <img className="cursor-pointer swiper-next" src={RightArr} alt="" />
          </div>
        </div>

        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={10}
          slidesPerView={4}
          grabCursor={true}
          navigation={{
            prevEl: ".swiper-prev",
            nextEl: ".swiper-next",
          }}
          onSwiper={handleSwiperUpdate}
        >
          {
            getPartnersData?.result.length > 0 &&
            getPartnersData.result.map(item => (
              <SwiperSlide key={item.id}>
                <div className=" px-10  bg-[#F5F5F5] rounded-lg w-full h-[169px] flex items-center justify-center">
                  <img src={`https://backend.morobolsin.uz/${item.logo}`} alt="" className='' />
                </div>
              </SwiperSlide>
            ))
          }
        </Swiper>
      </div>
    </div>
  )
}
