import React, { useContext, useEffect, useMemo, useState } from 'react'
import support from '../../assets/imgs/support.png'
import { icons } from '../../utilits/icons'
import { usePostMassageSupportMutation } from '../../RTXqueryApi/AllApi'
import { Contexts } from '../../context/Context'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function Support() {
  const { langData } = useContext(Contexts)
  const [phone, setPhone] = useState("")
  const [name, setName] = useState("")
  const massageSendSuc = () => toast.success('Ma`lumot jonatildi');

  const [postMassageSupport, { data: postMassageSupportData, isLoading: postMassageSupportLoading, isSuccess: postMassageSupportSuc }] = usePostMassageSupportMutation()

  const massageData = {
    method: "support",
    params: {
      name: name,
      phone: phone
    }
  }

  const massageFnc = (e) => {
    e.preventDefault()
    if (phone.length > 0 && name.length > 0) {
      postMassageSupport(massageData)
      setPhone("")
      setName("")
    }
  }
  useEffect(() => {
    postMassageSupportSuc && massageSendSuc()

  }, [postMassageSupportData, postMassageSupportSuc])




  return (
    <div className='container mt-[100px] mx-auto'>
      <div className="flex gap-9 justify-between ">
        <img src={support} alt="" className='rounded-3xl w-[40%]' />
        <div className="p-[40px] flex flex-col justify-between bg-white rounded-3xl border-[12px] border-gray-100">
          <div className="">
            <h3 className='text-[40px] font-medium'>{langData.supportInfo}</h3>
            <p className='text-[18px] mt-[16px] text-[#737373]'>{langData.supportInfoText}</p>
          </div>
          <form onSubmit={(e) => massageFnc(e)} action="" className=' w-[70%] gap-10 grid'>
            <input value={phone} onChange={(e) => setPhone(e.target.value)} type="text" placeholder='+998 97 123 45 56' className='border-b-[2px] border-black py-[10px] text-[30px] outline-none' />
            <input value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder={langData.yourName} className='border-b-[2px] border-black py-[10px]  text-[30px] outline-none' />
            <div className="flex items-center">
              <button type="submit"  className='bg-[#1A213D] text-white py-[14px] px-6 rounded-[30px]'>{langData.sendMassage}</button>
              <button className='border-[1px] border-gray-500 rounded-full p-2'>{icons.arrowup}</button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer />
    </div>
  )
}
