import React, { useContext, useEffect, useState } from 'react';
import { Contexts } from '../../context/Context';
import { useGetAllCategoryMutation, useGetAllColorsMutation, useGetAllProductsMutation } from '../../RTXqueryApi/AllApi';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { icons } from '../../utilits/icons';
import { useParams } from 'react-router-dom';
import OurproductMap from '../../components/ourProduct/OurproductMap';

const minDistance = 1;

const Product = () => {
  const { id: productId } = useParams();
  const { setLoader, lang, langData } = useContext(Contexts);
  const [checkedCategories, setCheckedCategories] = useState([Number(productId)]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [priceRange, setPriceRange] = useState([0, 1000000]); // Initial price range

  const [getAllCategory, { data: categoryData, isLoading: categoryLoading, isSuccess: categorySuccess }] = useGetAllCategoryMutation();
  const [getAllProducts, { data: productsData, isLoading: productsLoading, isSuccess: productsSuccess }] = useGetAllProductsMutation();
  const [getAllColors, { data: colorsData, isLoading: colorsLoading, isSuccess: colorsSuccess }] = useGetAllColorsMutation();

  const productsBody = {
    method: "get_all_products",
    params: {
      category_ids: checkedCategories,
      color_ids: selectedColors,
      min_price: priceRange[0],
      max_price: priceRange[1],
    }
  };

  useEffect(() => {
    if (categoryLoading || colorsLoading || productsLoading) {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [categoryLoading, colorsLoading, productsLoading, setLoader]);

  // Effect for triggering API call when categories, colors, or productId change
  useEffect(() => {
    getAllProducts(productsBody);
  }, [checkedCategories, selectedColors, productId]);

  // Effect for triggering API call with delay when priceRange changes
  useEffect(() => {
    const timer = setTimeout(() => {
      getAllProducts(productsBody);
    }, 1000);

    // Cleanup function to clear the timeout if the component unmounts or priceRange changes
    return () => clearTimeout(timer);
  }, [priceRange]);

  useEffect(() => {
    setCheckedCategories([Number(productId)]);
  }, [productId]);

  useEffect(() => {
    getAllCategory({ method: "get_all_categories" });
  }, [getAllCategory]);

  useEffect(() => {
    getAllColors({ method: "get_colors" });
  }, [getAllColors]);

  useEffect(() => {
    if (colorsSuccess && colorsData?.status) {
      const allColorIds = colorsData.result.map(color => color.id);
      setSelectedColors(allColorIds);
    }
  }, [colorsSuccess, colorsData]);

  const handleCategoryChange = (id) => {
    setCheckedCategories((prev) => {
      if (prev.includes(id)) {
        return prev.filter((catId) => catId !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  const handleColorChange = (id) => {
    setSelectedColors((prev) => {
      if (prev.includes(id)) {
        return prev.filter((colorId) => colorId !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  const handlePriceChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setPriceRange([Math.min(newValue[0], priceRange[1] - minDistance), priceRange[1]]);
    } else {
      setPriceRange([priceRange[0], Math.max(newValue[1], priceRange[0] + minDistance)]);
    }
  };

  const nameLangKey = `name_${lang}`;

  const currency = (number, currency, lang = undefined) =>
    Intl.NumberFormat(lang, { style: "currency", currency }).format(number);

  return (
    <div className="container mx-auto mt-[62px]">
      <div className='flex w-full mb-[24px] items-center justify-between'>
        <h3 className="font-semibold text-[20px]">Maxsulot turlari</h3>
        <div className='flex items-center gap-[20px]'>
          <p className='text-[14px] text-[#96999E]'>
            Topilgan maxsulotlar: <span className='text-[black]'>{productsSuccess ? productsData?.result?.products?.length : "0"} ta</span>
          </p>
          <div className='w-[1px] bg-[#A7AAAE] h-[32px]'></div>
          <p className='flex items-center justify-between w-[237px]'>Saralash <span className='cursor-pointer'>{icons.arrowdownblack}</span></p>
        </div>
      </div>
      <div className='flex gap-[38px]'>
        <div className="w-[26%] mt-[5px]">
          <div>
            <ul>
              {categoryData?.result.map((item, index) => (
                item.parent_id === 0 &&
                <div key={index}>
                  <li className='flex items-center justify-between mb-[12px]'>
                    <div className='flex items-center'>
                      <input
                        type="checkbox"
                        className='accent-[black] h-4 w-4 cursor-default'
                        id={`parent_${item.id}`}
                        checked={checkedCategories.includes(item.id)}
                        onChange={() => handleCategoryChange(item.id)}
                      />
                      <label className='ml-[8px] font-semibold' htmlFor={`parent_${item.id}`}>{item[nameLangKey]}</label>
                    </div>
                    <p className='font-semibold'>({item.product_count})</p>
                  </li>
                  <ul className='ml-[10px] text-[14px]'>
                    {item.all_children?.map((child, childIndex) => (
                      <li className='my-[8px] flex items-center justify-between' key={childIndex}>
                        <div className='flex items-center gap-[8px]'>
                          <input
                            type="checkbox"
                            className='accent-[black] h-3 w-3 cursor-default'
                            id={`child_${child.id}`}
                            checked={checkedCategories.includes(child.id)}
                            onChange={() => handleCategoryChange(child.id)}
                          />
                          <span>{child[nameLangKey]}</span>
                        </div>
                        <p>({child.product_count})</p>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </ul>
          </div>

          <div className="mb-4 mt-[49px]">
            <h3 className="font-semibold mb-2">Narx bo'yicha filtr</h3>
            <div className='flex items-center gap-[26px] mt-[32px] mb-[24px]'>
              <div className='bg-[#EDEEEF] rounded-[8px]'>
                <p className='text-[20px] py-[10px] px-[20px] text-[#575B5F] font-semibold'>
                {currency(priceRange[0], 'UZS').replace("UZS", "")
                    .replace("soʻm", "").replace(/,/g, " ").slice(0, -3).replace('.', " ")} {langData.sum}
                </p>
              </div>
              <div className='bg-[#EDEEEF] rounded-[8px]'>
                <p className='text-[20px] py-[10px] px-[20px] text-[#575B5F] font-semibold'>
                  {currency(priceRange[1], 'UZS').replace("UZS", "")
                    .replace("soʻm", "").replace(/,/g, " ").slice(0, -3).replace('.', " ")}
                  {langData.sum}
                </p>
              </div>
            </div>
            <Slider
              value={priceRange}
              onChange={handlePriceChange}
              valueLabelDisplay="auto"
              color='black'
              min={0}
              max={1000000}
              step={10}
              disableSwap
            />
          </div>

          <div className="mb-4 mt-[48px]">
            <h3 className="font-semibold mb-2">Maxsulot ranglari</h3>
            <ul>
              {colorsSuccess && colorsData.status && colorsData.result.map((item, index) => (
                <li className='flex items-center justify-between mb-[12px]' key={index}>
                  <div className='flex items-center'>

                    <input
                      type="checkbox"
                      className={` w-[20px] h-[20px]`}
                      style={{
                        accentColor: `${item.name_en}`,
                        borderColor: "gray"
                      }}
                      id={`color_${item.id}`}
                      checked={selectedColors.includes(item.id)}
                      onChange={() => handleColorChange(item.id)}
                    />
                    <label className='ml-[8px]' htmlFor={`color_${item.id}`}>{item[nameLangKey]}</label>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          <button className="w-full bg-[#E9EAEE] text-[#555D6F] px-4 py-2 rounded hover:bg-[#cbcccf]" onClick={() => {
            setCheckedCategories([productId]);
            setPriceRange([0, 1000000]); 
          }}>Filtrlarni o'chirish</button>
        </div>
        <div className="w-3/4 h-[500px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {productsSuccess && productsData.status && productsData.result.products.length > 0 ?
            productsData.result.products.map(item => (
              <OurproductMap key={item.id} item={item} />
            ))
            : <h1>...loading</h1>
          }
        </div>
      </div>
    </div>
  );
}

export default Product;
