import React, { useContext, useState } from 'react'
import instagram from '../../assets/imgs/instagram.png'
import { icons } from '../../utilits/icons'
import LeftArr from "../../assets/icons/roundedArrLeft.svg"
import RightArr from "../../assets/icons/roundedarrRight.svg"
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Contexts } from '../../context/Context'

export default function Instagram() {
  const [ishover, setishover] = useState(false)
  const [swiper, setSwiper] = useState(null);
  const { langData } = useContext(Contexts)

  const handleSwiperUpdate = (swiperInstance) => {
    setSwiper(swiperInstance);
    swiperInstance.on('slideChange', () => {
      updateButtonOpacity(swiperInstance);
    });
    // Set initial button opacity
    updateButtonOpacity(swiperInstance);
  };

  const updateButtonOpacity = (swiperInstance) => {
    const isBeginning = swiperInstance.isBeginning;
    const isEnd = swiperInstance.isEnd;
    const prevButton = document.querySelector('.swiper-prev1');
    const nextButton = document.querySelector('.swiper-next1');

    if (prevButton && nextButton) {
      if (isBeginning) {
        prevButton.style.opacity = '0.2';
      } else {
        prevButton.style.opacity = '1';
      }

      if (isEnd) {
        nextButton.style.opacity = '0.2';
      } else {
        nextButton.style.opacity = '1';
      }
    }
  };

  const slides = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  return (
    <div className='container mt-[50px] mx-auto'>
      <div className="flex justify-between items-center mb-[30px]">
        <h2 className='text-[30px] leading-[45px] font-medium tracking-wide'>{langData.instagramPAge} - <a href="" target='_blank' className='text-blue-500'>@morobolsin</a></h2>
        <div className="flex gap-[12px]">
          <img className="cursor-pointer swiper-prev1" src={LeftArr} alt="" />
          <img className="cursor-pointer swiper-next1" src={RightArr} alt="" />
        </div>
      </div>
      <div className="">
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={10}
          slidesPerView={4}
          grabCursor={true}
          navigation={{
            prevEl: ".swiper-prev1",
            nextEl: ".swiper-next1",
          }}
          onSwiper={handleSwiperUpdate}
        >
          <SwiperSlide>
            <div className="cursor-pointer w-full h-[360px] flex items-center justify-center hover:brightness-90 bg-no-repeat" style={{ backgroundImage: `url(${instagram})` }} onMouseEnter={() => setishover(true)} onMouseLeave={() => setishover(false)}>
              <div className={` text-center ${ishover ? " opacity-100 duration-200" : " opacity-0 duration-200"}`}>
                <span className='m-auto inline-flex'>{icons.instagram}</span>
                <p className='text-white underline'>@Morobolsin</p>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  )
}
