import React, { useContext, useState } from 'react'
import { icons } from '../../utilits/icons'
import { Contexts } from '../../context/Context'

export default function Order() {
  const [orderone, setorderone] = useState('')
  const { langData } = useContext(Contexts)
  return (
    <div className='container mx-auto my-[80px]'>
      <div className="flex items-center gap-1 justify-between min-h-[256px]">
        <div onMouseEnter={() => setorderone('1')} onMouseLeave={() => setorderone('')} className={`rounded-l-lg w-1/3 p-6 duration-200 min-h-[256px] ${orderone == '1' ? 'text-white bg-[#1A213D]' : 'text-black bg-[#F5F5F5]'}`}>
          <span className=''>{icons.medal}</span>
          <div className="flex mt-[66px] items-center justify-between">
            <p className=' w-2/3 text-[24px] font-light leading-9'>{langData.personalizeLogo}</p>
            <button className={`bg-white text-black p-4 rounded-full ${orderone == '1' ? "block" : 'hidden'} `}>{icons.arrowright}</button>
          </div>
        </div>
        <div onMouseEnter={() => setorderone('2')} onMouseLeave={() => setorderone('')} className={`w-1/3 p-6 duration-200 min-h-[256px] ${orderone == '2' ? 'text-white bg-[#1A213D]' : 'text-black bg-[#F5F5F5]'}`}>
          <span className=''>{icons.star}</span>
          <div className="flex mt-[66px] items-center justify-between">
            <p className='w-2/3 text-[24px]  font-light leading-9'>{langData.prepareUniforms}</p>
            <button className={`bg-white text-black p-4 rounded-full ${orderone == '2' ? "block" : 'hidden'} `}>{icons.arrowright}</button>
          </div>
        </div>
        <div onMouseEnter={() => setorderone('3')} onMouseLeave={() => setorderone('')} className={`rounded-r-lg w-1/3 p-6 duration-200 min-h-[256px] ${orderone == '3' ? 'text-white bg-[#1A213D]' : 'text-black bg-[#F5F5F5]'}`}>
          <span className=''>{icons.comment}</span>
          <div className="flex mt-[66px] items-center justify-between">
            <p className='w-2/3 text-[24px]  font-light leading-9'>{langData.canHelp}</p>
            <button className={`bg-white text-black p-4 rounded-full ${orderone == '3' ? "block" : 'hidden'} `}>{icons.arrowright}</button>
          </div>
        </div>
      </div>
    </div>
  )
}
