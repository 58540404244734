import React, { useContext, useEffect } from 'react'
import foiz from "../../assets/icons/foiz.svg"
import arrowup from "../../assets/icons/arrowup.svg"
import banner from "../../assets/imgs/banner.png"
import { useGetBannerMutation } from '../../RTXqueryApi/AllApi'
import { Contexts } from '../../context/Context'

function Banner() {
    const { setLoader, lang, langData } = useContext(Contexts)
    const [getBanner, { data: getBannerData, isLoading: getBannerLoading, isSuccess: getBannerSuc }] = useGetBannerMutation()
    const bannerData = {
        method: "get_banners"
    }
    useEffect(() => {
        getBanner(bannerData)
    }, [])
    useEffect(() => {
        setLoader(!getBannerSuc)
    }, [getBannerSuc])


    const titleLangKey = `title_${lang}`;


    return (
        <div className='container mx-auto'>
            {getBannerData?.result.length > 0 &&
                getBannerData.result.map(item => (
                    <div key={item.id} className="rounded-lg bg-no-repeat bg-cover bg-right" style={{ backgroundImage: `url(https://backend.morobolsin.uz/${item.bg_image})` }}>
                        <div className="ml-[80px] py-20">
                            <button className='bg-[#82F50F] flex items-center midiumJeko  gap-2 text-[32px] text-white rounded-lg py-[10px] px-[24px] mb-[28px]'><img src={foiz} alt="" />{item.value} {langData.sale}</button>
                            <h2 className='text-[40px] lightJecko  w-[40%] leading-[50px]'>{item[titleLangKey]}</h2>
                            <div className="flex items-center lg:mt-[180px]">
                                <button className='py-[14px] px-[36px] bg-white rounded-[30px] whitespace-nowrap'>{langData.startBuy}</button>
                                <button className='bg-[#1A213D] p-1 mr-5 rounded-[50%]'><img src={arrowup} alt="" /></button>
                                <p className='text-[12px] text-[#595959] font-light w-[18%]'>{langData.collection}</p>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default Banner