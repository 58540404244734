import React, { useContext, useEffect } from 'react'
import foiz from "../../assets/icons/foiz.svg"
import arrowup from "../../assets/icons/arrowup.svg"
import banner from "../../assets/imgs/banner2.png"
import { useGetBanner2Mutation } from '../../RTXqueryApi/AllApi'
import { Contexts } from '../../context/Context'

export default function Bannertwo() {
  const { lang, setLoader } = useContext(Contexts)
  const [getBanner2, { data: getBanner2Data, isLoading: getBanner2Loading, isSuccess: getBanner2Suc }] = useGetBanner2Mutation()
  const bannerData = {
    method: "get_bottom_banners"
  }
  useEffect(() => {
    getBanner2(bannerData)
  }, [])
  useEffect(() => {
    setLoader(!getBanner2Suc)
  }, [getBanner2Suc])


  const textLangKey = `text_${lang}`
  const titleLangKey = `title_${lang}`

  return (
    <div>
      <div className='container mx-auto my-[60px]'>
        {
          getBanner2Data?.result.length > 0 &&
          getBanner2Data.result.map(item => (
            <div key={item.id} className="rounded-lg bg-no-repeat bg-cover bg-right" style={{ backgroundImage: `url(https://backend.morobolsin.uz/${item.bg_image})` }}>
              <div className="p-6">
                <div className="bg-white lg:w-[30%] rounded-r-[80px] rounded-l-[14px] py-10 px-7">
                  <h2 className='text-[30px] lightJec  leading-[37px]'>{item[titleLangKey]}</h2>
                  <button className='bg-white leading-[130px] text-[86px] mt-[88px] rounded-[30px] whitespace-nowrap text-[#82F50F]'>{item.value}</button>
                  <p className='text-[16px] text-[#595959] leading-6'>{item[textLangKey]} </p>
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}
