import React, { useEffect, useState } from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/home/Home';
import Navbar from './components/navbar/Navbar';
import BlackTop from './components/blackTop/BlackTop';
import Search from './pages/search/Search';
import { Contexts } from './context/Context';
import Loader from './components/loader/Loader';
import Register from './pages/register/Register';
import Login from './pages/login/Login';
import Footer from './components/footer/Footer';
import Otp from './pages/otp/Otp';
import Product from './pages/products/Product';
import ProductDetail from './pages/productDetail/ProductDetail';

function App() {
  const [activresupmenu, setActivesupmenu] = useState(false)
  const [opensearch, setOpensearch] = useState(false)
  const [loader, setLoader] = useState(true)
  const [lang, setLang] = useState("ru");
  const [langData, setLangData] = useState({});


  return (
    <div className={`App relative`}>
      <Contexts.Provider value={{
        activresupmenu, setActivesupmenu,
        opensearch, setOpensearch,
        loader, setLoader,
        lang, setLang,
        langData, setLangData
      }}>
        <BlackTop />
        <Navbar />
        {opensearch && <Search />}
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/product/:id' element={<Product />} />
          <Route path='/register' element={<Register />} />
          <Route path='/login' element={<Login />} />
          <Route path='/otp' element={<Otp />} />
          <Route path='/productDetail/:productId' element={<ProductDetail />} />
        </Routes>
        
        <Footer />
        {loader && <Loader />}
      </Contexts.Provider>
    </div>
  );
}

export default App;
