import React, { useContext, useEffect, useState } from 'react'
import { icons } from '../../utilits/icons'
import chef4 from '../../assets/imgs/chef4.png'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Contexts } from '../../context/Context';
import { useGetAllProductsMutation } from '../../RTXqueryApi/AllApi';
import OurproductMap from './OurproductMap';

export default function Ourproduct() {
    const { langData, setLoader, lang } = useContext(Contexts)
    const [getAllProducts, { data: getAllProductsData, isLoading: getAllProductsLoading, isSuccess: getAllProductsSuc }] = useGetAllProductsMutation();

    const productsBody = {
        method: "get_all_products"
    };

    useEffect(() => {
        getAllProducts(productsBody);
    }, []);

    useEffect(() => {
        setLoader(!getAllProductsSuc);
    }, [getAllProductsSuc]);



    console.log(getAllProductsData)
    return (
        <div className="container mx-auto">
            <h2 className='text-[30px] mb-5'>{langData.allProducts}</h2>
            
            <div className="grid grid-cols-4 gap-2">
                {
                    getAllProductsData?.result.products.length > 0 &&
                    getAllProductsData?.result.products.map(item => (
                        <OurproductMap key={item.id} item={item} />
                    ))
                }
            </div>
            {
                getAllProductsData?.result.length > 10 &&
                <button className='w-full py-[18px] bg-[#EBEBEB] rounded-xl text-[16px] font-medium leading-6 mt-5'> {langData.seeOneMore}</button>
            }
        </div>
    )
}
