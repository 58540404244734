import React from 'react'
import Banner from '../../components/banner/Banner'
import Category from '../../components/category/Category'
import OurWorks from '../../components/ourWorks/OurWorks'
import TrendProducts from '../../components/trendProducts/TrendProducts'
import Order from '../../components/ourorder/Order'
import Ourproduct from '../../components/ourProduct/Ourproduct'
import Bannertwo from '../../components/banner2/Bannertwo'
import Partners from '../../components/partners/Partners'
import Support from '../../components/support/Support'
import Instagram from '../../components/instagram/Instagram'
import Footer from '../../components/footer/Footer'

function Home() {
  return (
    <div>
      <Banner />
      <Category />
      {/* <OurWorks /> */}
      <TrendProducts />
      <Order />
      <Ourproduct />
      <Bannertwo />
      <Partners />
      <Support />
      <Instagram />
      {/* <Footer/> */}
    </div>
  )
}

export default Home