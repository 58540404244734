import React, { useContext, useState, useRef, useEffect } from 'react';
import loginBg from "../../assets/imgs/loginBg.png";
import logo from "../../assets/icons/logo.svg";
import logoWhite from "../../assets/icons/logoWhite.svg";
import googleBg from "../../assets/icons/googleBg.svg";
import appleBg from "../../assets/icons/appleBg.svg";
import facebookBg from "../../assets/icons/facebookBg.svg";
import { useNavigate } from 'react-router-dom';
import { Contexts } from '../../context/Context';
import { useVerificationOtpMutation, useResendOtpMutation } from '../../RTXqueryApi/AllApi';

function Otp() {
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const [timer, setTimer] = useState(120);
    const [verificationOtp, { data: verificationOtpData, isSuccess: verificationOtpSuc }] = useVerificationOtpMutation();
    const [resendOtp, { data: resendOtpData, isSuccess: resendOtpSuc }] = useResendOtpMutation();
    const otpBoxReference = useRef([]);

    const { langData } = useContext(Contexts)
    const navigate = useNavigate();

    const startTimer = () => {
        const countdown = setInterval(() => {
            setTimer(prevTimer => {
                if (prevTimer <= 1) {
                    clearInterval(countdown);
                    resendOtpFnc();
                    return 0;
                }
                return prevTimer - 1;
            });
        }, 1000);
    };

    useEffect(() => {
        startTimer();
    }, []);

    const resendOtpFnc = () => {
        const email = localStorage.getItem('clientEmail');
        const otpData = {
            method: "resendClientOtp",
            params: {
                email,
            }
        };
        resendOtp(otpData);
    };

    const verificationOtpFnc = (e) => {
        e.preventDefault();
        if (otp.every(value => value !== "")) {
            const otpData = {
                method: "verifyClientOtp",
                params: {
                    email: localStorage.getItem('clientEmail'),
                    otp: otp.join("")
                }
            };
            verificationOtp(otpData);
        }
    };

    function handleChange(value, index) {
        let newArr = [...otp];
        newArr[index] = value;
        setOtp(newArr);

        if (value && index < 6 - 1) {
            otpBoxReference.current[index + 1].focus();
        }
    }

    function handleBackspaceAndEnter(e, index) {
        if (e.key === "Backspace" && !e.target.value && index > 0) {
            otpBoxReference.current[index - 1].focus();
        }
        if (e.key === "Enter" && e.target.value && index < 6 - 1) {
            otpBoxReference.current[index + 1].focus();
        }
    }

    useEffect(() => {
        if (verificationOtpData?.status === true ) {
            localStorage.setItem('clientToken', verificationOtpData?.result.token );
            navigate("/");
        }
    }, [verificationOtpData, verificationOtpSuc]);

    console.log(resendOtpData)

    return (
        <div>
            <div style={{ backgroundImage: `url(${loginBg})` }} className='bg-cover w-full h-[100vh] bg-center p-[16px] flex items-start justify-between'>
                <img onClick={() => navigate("/")} className='p-[60px] cursor-pointer' src={logo} alt="" />
                <form onSubmit={(e) => verificationOtpFnc(e)} className='px-[24px] py-[40px] h-[95vh] rounded-[16px] flex items-center justify-between flex-col bg-[white]'>
                    <div className='w-full'>
                        <div className='bg-[#1A213D] flex items-center justify-center w-[70%] mx-[auto] py-[22px] rounded-[12px]'>
                            <img src={logoWhite} alt="" />
                        </div>
                        <h1 className='text-[24px] extraBoldJeko text-center mt-[29px] mb-[9px]'>Otp</h1>
                        <p className='text-[14px] JekoMedium text-center text-[#AAAAAA] font-normal w-[309px] mx-auto'>Kirish uchun kod SMS habarnoma yuborildi</p>
                        <p className='text-[14px] mt-[12px] JekoMedium text-center text-[red] font-normal w-[309px] mx-auto'>{verificationOtpData?.status === false && verificationOtpData.error.message ? verificationOtpData.error.message : ''}</p>
                        <div className='flex items-center gap-[5px] '>
                            {otp.map((digit, index) => (
                                <input key={index} value={digit} maxLength={1}
                                    onChange={(e) => handleChange(e.target.value, index)}
                                    onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                                    ref={(reference) => (otpBoxReference.current[index] = reference)}
                                    className={`border w-[70px] h-auto text-[black] p-3 rounded-[72px] block bg-[#F6F6F6] focus:border-2 focus:outline-none appearance-none`}
                                />
                            ))}
                        </div>
                        <div className='flex items-center justify-between mt-[3%]'>
                            <p>Resend Otp while </p>
                            <p>{timer}s</p>
                        </div>
                    </div>
                    <div className='w-full mt-[42%]'>
                        <button type='submit' className='bg-[#D2D3D8] JekoMedium rounded-[72px] w-full text-[16px] text-[#fff] py-[15px] mt-[12px]'>{langData.register}</button>
                        <div className='flex items-center justify-between gap-[10px] mt-[30px]'>
                            <div className='bg-[#f6f6f6] w-full h-[1px]'></div>
                            <p className='w-full text-[12px] whitespace-nowrap text-[#aaaaaa]'>{langData.orThrowThis}</p>
                            <div className='bg-[#f6f6f6] w-full h-[1px]'></div>
                        </div>
                        <div className='mt-[24px] justify-center flex items-center gap-[30px]'>
                            <img className='cursor-pointer' src={googleBg} alt="" />
                            <img className='cursor-pointer' src={appleBg} alt="" />
                            <img className='cursor-pointer' src={facebookBg} alt="" />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Otp;
