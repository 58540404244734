import React, { useContext, useEffect } from 'react';
import map from "../../assets/icons/map.svg";
import arrDown from "../../assets/icons/arrowdown.svg";
import phone from "../../assets/icons/phone.svg";
import clock from "../../assets/icons/clock.svg";
import earth from "../../assets/icons/earth.svg";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Contexts } from '../../context/Context';
import { useLocation } from 'react-router-dom';

function BlackTop() {
    const location = useLocation()
    const { setLang, lang, langData } = useContext(Contexts);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (url) => {
        window.location.href = url;
    };

    useEffect(() => {
        if (window.location.search.length > 4) {
            setLang(window.location.search.replace('?lng=', ''));
        }
    }, []);

    const languageLabels = {
        uz: 'O’zbek tili',
        ru: 'Русский язык',
        en: 'English'
    };

    return (
        <div className={`bg-[#2E2E2E] h-[50px] flex items-center ${location.pathname == "/login" | location.pathname == "/register" | location.pathname == "/otp"  &&  'hidden'}`}>
            <div className='container mx-auto flex items-center justify-between'>
                <div className='flex items-center gap-[26px] text-[#fff] text-[12px] font-light'>
                    <p className='flex items-center gap-[8px]'><img src={map} alt="" />{langData.shopping_center} - TOSHKENT</p>
                    <a href="tel:+998977577227">
                        <p className='flex items-center gap-[8px]'>
                            <img src={phone} alt="" />
                            +998 (97) 757 72 27
                        </p>
                    </a>
                </div>
                <div className='flex items-center gap-[26px] text-[#fff] text-[12px] font-light'>
                    <p className='flex items-center gap-[8px]'><img src={clock} alt="" />10:00 AM - 9:00 PM</p>
                    <p onClick={handleClick} className='flex items-center gap-[8px]'>
                        <img src={earth} alt="" />
                        {languageLabels[lang]}
                        <img className='cursor-pointer' src={arrDown} alt="" />
                    </p>
                    <Menu
                        id="basic-menu"
                        className='ml-[20px]'
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        {Object.keys(languageLabels).map((key) => (
                            <MenuItem
                                key={key}
                                className='capitalize'
                                onClick={() => { handleMenuItemClick(`?lng=${key}`); handleClose(); }}
                            >
                                {languageLabels[key]}
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
            </div>
        </div>
    );
}

export default BlackTop;
