import React, { useContext, useEffect, useState } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Contexts } from '../../context/Context';
import { useGetAllProductsMutation } from '../../RTXqueryApi/AllApi';
import TrendProductsMap from './TrendProductsMap';

function TrendProducts() {
    const { langData, setLoader, } = useContext(Contexts)

    const [getAllProducts, { data: getAllProductsData, isLoading: getAllProductsLoading, isSuccess: getAllProductsSuc }] = useGetAllProductsMutation();

    const productsBody = {
        method: "get_all_products"
    };

    useEffect(() => {
        getAllProducts(productsBody);
    }, []);

    useEffect(() => {
        setLoader(!getAllProductsSuc);
    }, [getAllProductsSuc]);

    return (
        <div className="container mx-auto mt-[60px]">
            <h2 className='text-[30px] mb-[30px]'>{langData.trendyProducts}</h2>
            <div className="grid grid-cols-4 gap-2">
                {
                    getAllProductsData?.result.products.length > 0 &&
                    getAllProductsData?.result.products.map(item => (
                        item.attributes[0]?.value == "on" &&
                        <TrendProductsMap key={item.id} item={item} />
                    ))
                }
            </div>
        </div>
    )
}

export default TrendProducts