import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetProductDetailMutation } from '../../RTXqueryApi/AllApi';
import arrLeft from "../../assets/icons/arrLeft.svg";
import { Contexts } from '../../context/Context';
import penMagic from "../../assets/icons/magicpen.svg"
import arrRight from "../../assets/icons/arrow-right.svg"
import heart from "../../assets/icons/heart.svg"

function ProductDetail() {
    const id = useParams();
    const { lang, langData, loader, setLoader } = useContext(Contexts)
    const [getProductDetail, { data: getProductDetailData, isSuccess: getProductDetailSuc, isLoading: getProductDetailLoading }] = useGetProductDetailMutation();
    const [mainImage, setMainImage] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);

    const getProductDetailBody = {
        method: "product_details",
        params: {
            id: id
        }
    };

    useEffect(() => {
        getProductDetail(getProductDetailBody);
    }, [id]);

    useEffect(() => {
        if (getProductDetailData?.status === true && getProductDetailSuc) {
            setMainImage(`https://backend.morobolsin.uz/${getProductDetailData.result[0]?.files[0]?.path}`);
            setCurrentIndex(0);  // Reset index when data changes
        }
        if (getProductDetailSuc) {
            setLoader(true)
        }
    }, [getProductDetailData, getProductDetailSuc]);

    useEffect(() => {
        setLoader(false)
    }, [getProductDetailLoading])


    const handleThumbnailClick = (path, index) => {
        setMainImage(`https://backend.morobolsin.uz/${path}`);
        setCurrentIndex(index);
    };

    const handlePrevClick = () => {
        if (currentIndex > 0) {
            const newIndex = currentIndex - 1;
            setMainImage(`https://backend.morobolsin.uz/${getProductDetailData.result[0]?.files[newIndex].path}`);
            setCurrentIndex(newIndex);
        }
    };

    const handleNextClick = () => {
        if (currentIndex < getProductDetailData.result[0]?.files.length - 1) {
            const newIndex = currentIndex + 1;
            setMainImage(`https://backend.morobolsin.uz/${getProductDetailData.result[0]?.files[newIndex].path}`);
            setCurrentIndex(newIndex);
        }
    };



    const currency = (number, currency, lang = undefined) =>
        Intl.NumberFormat(lang, { style: "currency", currency }).format(number);


    const NameLangKey = `name_${lang}`


    console.log(getProductDetailData)


    return (
        <div className='container mx-auto mt-[36px] flex gap-[44px]'>
            <div className='flex '>
                <div className='flex flex-col min-w-[140px] h-[800px] '>
                    {getProductDetailData?.status === true && getProductDetailSuc && getProductDetailData.result[0]?.files.map((item, index) => (
                        <img
                            key={index}
                            src={`https://backend.morobolsin.uz/${item.path}`}
                            alt={`thumbnail-${index}`}
                            className={`cursor-pointer w-[108px] h-[108px] mb-[12px] rounded-[12px] ${index === currentIndex ? 'border-2 border-black' : ''}`}
                            onClick={() => handleThumbnailClick(item.path, index)}
                        />
                    ))}
                </div>
                <div className="main-image-container relative">
                    <img className='rounded-[12px]' src={mainImage} alt="main" />
                    <img
                        onClick={handlePrevClick}
                        className='absolute left-0 top-1/2 transform cursor-pointer -translate-y-1/2 bg-transparent ml-[23px]'
                        disabled={currentIndex === 0}
                        src={arrLeft} alt="Previous"
                    />
                    <img
                        onClick={handleNextClick}
                        className='absolute rotate-[180deg] right-0 cursor-pointer top-1/2 transform -translate-y-1/2 bg-transparent mr-[23px]'
                        disabled={currentIndex === getProductDetailData?.result[0]?.files.length - 1}
                        src={arrLeft} alt="Next"
                    />
                </div>
            </div>
            <div className='py-[32px] w-[50%] '>
                <h1 className='text-[36px] font-bold font-[inter] mb-[16px]' >{getProductDetailData?.status === true && getProductDetailSuc && getProductDetailData.result[0][NameLangKey]}</h1>
                <p className='text-[22px] font-medium'>
                    {getProductDetailData?.status === true && getProductDetailSuc && getProductDetailData.result[0].price && currency(getProductDetailData?.status === true && getProductDetailSuc && getProductDetailData.result[0].price, 'UZS').replace("UZS", "")
                        .replace("soʻm", "").replace(/,/g, " ").slice(0, -3).replace('.', " ")} {langData.sum}
                </p>
                <p className='mt-[48px] mb-[8px] text-[16px] font-medium font-[inter]'>Miqdor chegirmasi mavjud</p>
                <div className='bg-[#F5F5F5] py-[20px] px-[16px] rounded-[8px] flex items-center justify-between gap-[40px]'>
                    {
                        getProductDetailData?.status == true &&
                        getProductDetailData?.result[0].price_ranges.map((item, index) => (
                            <h2 key={index} className='text-[20px] font-semibold'>
                                <p className='text-[14px] text-[#96999E] font-normal'>
                                    {item.from}-{item.to} gacha
                                </p>
                                {item.price} {item.currency}
                            </h2>
                        ))
                    }
                </div>
                <div className='my-[32px]'>
                    <div className='flex items-center justify-between gap-[30px]'>
                        <p className='bg-[#F5F5F5] py-[12px] w-full flex items-center justify-center text-[14px] cursor-pointer text-[#575B5F]   rounded-t-[8px]'>Oddiy buyurtma</p>
                        <p className='bg-[#FBFBFB] py-[12px] w-full  flex items-center justify-center text-[14px] cursor-pointer text-[#575B5F]   rounded-t-[8px]'>Ko'p sonli buyurtma</p>
                    </div>
                    <div className='bg-[#F5F5F5] rounded-b-[8px] py-[32px] flex items-center gap-[16px] px-[16px]'>

                        <label className='text-[14px] text-[#96999E]' htmlFor="">
                            O’lcham
                            <input type="text" className='w-full mt-[8px] rounded-[4px] py-[19px] px-[16px] border border-[#B9BBBE] text-[16px] text-[#2C2D30] outline-none' placeholder='XS' />
                        </label>
                        <label className='text-[14px] text-[#96999E]' htmlFor="">
                            Tovar soni
                            <input type="text" className='w-full rounded-[4px] mt-[8px] py-[19px] px-[16px] border border-[#B9BBBE] text-[16px] text-[#2C2D30] outline-none' placeholder='1' />
                        </label>
                    </div>
                </div>
                <div className='w-[50%] mx-auto'>
                    <p className='flex items-center justify-center cursor-pointer text-[16px] gap-[24px]'>Naqshli ism qo'shing <img src={penMagic} alt="" /> </p>
                </div>
                <button className='w-full cursor-pointer flex items-center text-[16px] justify-center mt-[40px] mb-[16px] text-[#fff] bg-[#1A213D] rounded-[8px] py-[20px]'>
                    Savatga qo’shish <img className='ml-[24px]' src={arrRight} alt="" />
                </button>
                <button className='w-full cursor-pointer flex items-center text-[16px] justify-center  rounded-[8px] py-[20px] border border-[#B9BBBE]'>
                    Sevimlilarga qo’shish <img className='ml-[24px]' src={heart} alt="" />
                </button>
            </div>

        </div >
    );
}

export default ProductDetail;
