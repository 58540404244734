import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const AllApi = createApi({
    reducerPath: "api",
    baseQuery: fetchBaseQuery({
        baseUrl: "https://backend.morobolsin.uz",
    }),
    endpoints: (builder) => ({
        getAllCategory: builder.mutation({
            query: (categories) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: categories,
                headers: {
                    accept: 'application/json',
                }
            }),
        }),
        getOneCategory: builder.mutation({
            query: (category) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: category,
                headers: {
                    accept: 'application/json',
                }
            }),
        }),
        getBanner: builder.mutation({
            query: (banner) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: banner,
                headers: {
                    accept: 'application/json',
                }
            }),
        }),
        getBanner2: builder.mutation({
            query: (banner) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: banner,
                headers: {
                    accept: 'application/json',
                }
            }),
        }),
        getPartners: builder.mutation({
            query: (partners) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: partners,
                headers: {
                    accept: 'application/json',
                }
            }),
        }),
        postMassageSupport: builder.mutation({
            query: (massage) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: massage,
                headers: {
                    accept: 'application/json',
                }
            }),
        }),

        getAllProducts: builder.mutation({
            query: (product) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: product,
                headers: {
                    accept: 'application/json',
                }
            }),
        }),
        register: builder.mutation({
            query: (user) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: user,
                headers: {
                    accept: 'application/json',
                }
            }),
        }),
        verificationOtp: builder.mutation({
            query: (otp) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: otp,
                headers: {
                    accept: 'application/json',
                }
            }),
        }),
        resendOtp: builder.mutation({
            query: (otp) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: otp,
                headers: {
                    accept: 'application/json',
                }
            }),
        }),
        loginClient: builder.mutation({
            query: (client) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: client,
                headers: {
                    accept: 'application/json',
                }
            }),
        }),
        categoryDetails: builder.mutation({
            query: (categoryId) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: categoryId,
                headers: {
                    accept: 'application/json',
                }
            }),
        }),
        getAllColors: builder.mutation({
            query: (colors) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: colors,
                headers: {
                    accept: 'application/json',
                }
            }),
        }),
        getProductDetail: builder.mutation({
            query: (detailBody) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: detailBody,
                headers: {
                    accept: 'application/json',
                }
            }),
        }),
    })

});

export const {
    useGetAllCategoryMutation, useGetOneCategoryMutation,
    useGetBannerMutation, useGetBanner2Mutation,
    useGetPartnersMutation, usePostMassageSupportMutation,
    useGetAllProductsMutation, useRegisterMutation,
    useVerificationOtpMutation, useResendOtpMutation,
    useLoginClientMutation, useCategoryDetailsMutation,
    useGetAllColorsMutation, useGetProductDetailMutation
} = AllApi;
