import React, { useContext, useState } from 'react'
import { Contexts } from '../../context/Context';
import { Carousel } from 'react-responsive-carousel';
import { icons } from '../../utilits/icons'
import { useNavigate } from 'react-router-dom';

function TrendProductsMap({ item }) {
    const [activecount, setActivecount] = useState(false)
    const { langData, lang } = useContext(Contexts)
    const navigate = useNavigate()

    const currency = (number, currency, lang = undefined) =>
        Intl.NumberFormat(lang, { style: "currency", currency }).format(number);
    const NameLangKey = `name_${lang}`


    return (
        <div
            className="relative p-[10px] productCauousel rounded-xl hover:shadow-[0px_4px_14px_0px_#D3D3D340]"
            onMouseEnter={() => setActivecount(true)}
            onMouseLeave={() => setActivecount(false)}
        >

            <Carousel
                showArrows={true}
                autoPlay={false}
                interval={2000}
                showStatus={false}
                showIndicators={true}
                showThumbs={false}
                infiniteLoop={true}
                swipeable={true}
                dynamicHeight={true}
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                        <button type="button" onClick={onClickHandler} title={label} className={`absolute left-0 top-1/2 transform -translate-y-1/2 ${!activecount && 'hidden'}`}>
                            {icons.prev}
                        </button>
                    )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                        <button type="button" onClick={onClickHandler} title={label} className={`absolute right-0 top-1/2 transform -translate-y-1/2 ${!activecount && 'hidden'}`}>
                            {icons.next}
                        </button>
                    )
                }
            >
                {item.files.length > 0 && item.files.map(file => (
                    <div key={file.id}>
                        <img src={`https://backend.morobolsin.uz/${file.path}`} alt="" className="rounded-[12px] w-full h-[425px] p-0 m-0" />
                    </div>
                ))}
            </Carousel>
            <div className="flex items-center justify-between absolute top-4 w-full px-[10px]">
                {
                    item.attributes[1]?.id &&
                    <div className='flex items-center gap-[8px]  bg-white  px-[17px] py-[5px] rounded-[47px] text-[16px]'><span className='text-[16px]'>{icons.foizgreen}</span>{item.attributes[1]?.value}%</div>
                }
                {activecount && <button className='bg-white p-[8px]  rounded-full mr-[15px]'>{icons.like}</button>}
            </div>
            <div onClick={() => navigate(`/productDetail/${item.id}`)} className="flex mt-[16px] p-2 cursor-pointer items-start justify-between">
                <div className="grid">
                    <h2 className='text-[16px] font-light'>{item[NameLangKey]}</h2>
                    <p className='text-[18px] font-medium'>{item.price && currency(item.price, 'UZS').replace("UZS", "")
                        .replace("soʻm", "").replace(/,/g, " ").slice(0, -3).replace('.', " ")} {langData.sum}</p>
                </div>
                <div className="flex gap-2">
                    {
                        item.colors.length > 0 &&
                        item.colors.map((color, index) => (
                            <div
                                key={index} // Add a unique key

                                className={`border border-[#D7D7D7] rounded-full flex items-center justify-center w-[20px] h-[20px]`}

                            >
                                <div className={`rounded-full w-[18px] h-[18px]`} style={{ backgroundColor: color?.name_en }}>

                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default TrendProductsMap